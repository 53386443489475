import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import numeral from 'numeral';
import { num_to_money, make_format_string, money_to_num, date_to_string, date_to_moment } from '@/utils'

export default {
    props: {
        portfolio_cur: {
            type: String,
            required: true
        },
        portfolio_id: {
            type: String,
            required: true
        },
        trade_date: {
            type: Date,
            required: true
        },
        operation_type: {
            type: String,
            required: true
        },
    },

    data(){
        return {
            formdata: {
                isin              : undefined,
                tcur              : undefined,
                tpr               : undefined,
                q                 : undefined,
                gross_amount      : undefined,
                tcur_prate        : undefined,
                tcur_prate_amount : undefined,
            },
            isin_data: {},
            pricefields: {
                tpr          : 1,
                tcur_prate   : 1,
                tcur_srate   : 1,
                tcur_pt_rate : 1,
                st_cur_rate  : 1,
            },
            operationid: undefined,
            search_isin_not_found: false,
        }
    },

    computed: {
        ...mapState({
            currency_list : state => state.currency.list,
        }),

        show_conversion_rate(){
            if (!this.formdata.tcur || !this.formdata.isin)
                return false;
            else if (this.formdata.isin && this.formdata.isin === this.portfolio_cur)
                return false;
            else if (this.formdata.tcur && this.formdata.tcur === this.portfolio_cur)
                return false;
            return true;
        }
    },

    methods: {
        isin_search_async(queryString, cb){
            if (this.asset && queryString && queryString.length >= 2) {
                this.search_isin_not_found = false
                let url = Urls.portfolioSearchInstrumentTerm
                this.axios.get(url, {params: {
                    asset : this.asset,
                    date  : this.$moment(this.trade_date).utcOffset('+0300').format('YYYY-MM-DD'),
                    term  : queryString
                }})
                .then(response => {
                    let list = response.data._items
                    if (this.asset === 'future') {
                        let trade_date = this.$moment(this.trade_date).utcOffset('+0300')
                        list = list.filter(record => { 
                            let start_date   = this.$moment(record.basedate, "YYYY-MM-DD");
                            let end_date     = this.$moment(record.expdate, "YYYY-MM-DD");

                            return trade_date.isBetween(start_date, end_date, 'days', '[]');
                        });
                    }
                    cb(list);
                    if(!list.length) this.search_isin_not_found = true
                })
                .catch(e => {
                    console.log(e)
                    this.$message({
                        message: 'Cannot make search!',
                        type: 'error'
                    })
                    cb([]);
                    this.search_isin_not_found = false
                })
            }
            else {
                cb([]);
            }
        },

        async get_history_values(instid, asset, fields, date){
            if (!(instid && fields)){
                this.$message({
                    message: "Can't get history value.",
                    type: 'error'
                })
                return;
            }
            let hist_date = this.$moment(date).utcOffset('+0300').format('YYYY-MM-DD')
            let url = Urls.portfolioSearchInstrumentData
            return this.axios.get(url, {params:{
                asset  : asset, 
                ident  : instid, 
                fields : JSON.stringify(fields), 
                date   : hist_date
            }})
            .then(response => {
                return response.data
            })
            .catch(e => {
                console.log(e)
                this.$message({
                    message: "Can't get history value!",
                    type: 'error'
                })
            });
        },

        format_number_field(field){
            let digits = (this.pricefields[field]) ? 16 : 2
            let value = num_to_money(this.formdata[field], digits)
            return value
        },

        blur_format_field(field){
            let digits = (this.pricefields[field]) ? 16 : 2
            let format_string = make_format_string(digits + 1)
            let value = numeral(this.formdata[field]).format(format_string, Math.round)
            this.$set(this.formdata, field, value);
        },

        get_number_field(field){
            return (this.formdata[field]) ? money_to_num(this.formdata[field]) : 0;
        },

        get_bond_isin_price_data(){
            this.get_history_values(this.formdata.isin, this.asset, ['price', 'date', 'accrued_coupon', 'maturity', 'sinkfactor'], this.trade_date).then((response) => {
                if (!response) return;
                this.$set(this.isin_data, 'sinkfactor', response.sinkfactor)
                this.$set(this.isin_data, 'maturity', response.maturity)

                this.$set(this.formdata, 'tpr', response.price)
                this.$set(this.formdata, 'sinkfactor', response.sinkfactor)
                this.$set(this.formdata, 'coupon', response.accrued_coupon)
            })
        },

        get_equity_isin_price_data(){
            this.get_history_values(this.formdata.isin, this.asset, ['price'], this.trade_date).then((response) => {
                if (!response) return;
                this.$set(this.formdata, 'tpr', response.price)
            })
        },

        get_option_isin_price_data(){
            this.get_history_values(this.formdata.isin, this.asset, ['price'], this.trade_date).then((response) => {
                if (!response) return;
                this.$set(this.formdata, 'tpr', response.price)
            })
        },

        get_tcur_rates_data(){
            if (this.operationid) return;
            if (!this.formdata.tcur || this.formdata.tcur === this.portfolio_cur) {
                this.$set(this.formdata, 'tcur_prate', 1)
                return;
            }

            let ident = this.formdata.tcur + this.portfolio_cur
            this.get_history_values(ident, 'cash', ['price', 'date'], this.trade_date).then((response) => {
                this.$set(this.formdata, 'tcur_prate', response.price)
            })
        },

        clear_data(){
            this.isin_data = {}

            this.$set(this.formdata, 'isin', undefined)
            this.$set(this.formdata, 'tcur', undefined)
            this.$set(this.formdata, 'tpr', undefined)
            this.$set(this.formdata, 'q', undefined)
            this.$set(this.formdata, 'tcur_prate', undefined)
            this.$set(this.formdata, 'coupon', undefined)
            this.$set(this.formdata, 'sinkfactor', undefined)
            this.$set(this.formdata, 'st_cur', undefined)
            this.$set(this.formdata, 'st_cur_rate', undefined)

            this.$set(this.formdata, 'gross_amount', undefined)
            this.$set(this.formdata, 'tcur_prate_amount', undefined)
            this.$set(this.formdata, 'st_cur_rate_amount', undefined)
            this.$set(this.formdata, 'cutoff_dt', undefined)
        },

        fill_data(data){
            if (!data.isin) return;
            this.operationid = data._id
            this.$set(this.isin_data, 'isin', data.isin)

            this.$set(this.formdata, 'isin', data.isin)
            this.$set(this.formdata, 'tcur', data.tcur)
            this.$set(this.formdata, 'tpr', data.tpr)
            this.$set(this.formdata, 'q', data.q)
            this.$set(this.formdata, 'tcur_prate', data.tcur_prate)
            this.$set(this.formdata, 'coupon', data.coupon)
            this.$set(this.formdata, 'sinkfactor', data.sinkfactor)
            this.$set(this.formdata, 'st_cur', data.st_cur)
            this.$set(this.formdata, 'st_cur_rate', data.st_cur_rate)
            this.$set(this.formdata, 'size', data.size)

            if (data.cutoff_dt){
                var hrs_offset = -(new Date().getTimezoneOffset() / 60)
                this.$set(this.formdata, 'cutoff_dt', date_to_moment(data.cutoff_dt).utcOffset(hrs_offset).toDate());
            }

            this.set_isin_data(data);
        },

        change_tcur_prate_amount(){
            let tcur_prate_amount = this.get_number_field('tcur_prate_amount')
            let gross_amount = this.get_number_field('gross_amount')
            if (tcur_prate_amount && gross_amount)
                this.$set(this.formdata, 'tcur_prate', tcur_prate_amount / gross_amount);
            else
                this.$set(this.formdata, 'tcur_prate', 0);
        },

        change_st_cur(){
            if (this.operationid) return;
            if (!this.formdata.tcur) return;
            if (!this.formdata.st_cur) return;
            if (this.formdata.tcur === this.formdata.st_cur) {
                this.$set(this.formdata, 'st_cur_rate', 1);
                return;
            }

            let ident = this.formdata.tcur + this.formdata.st_cur
            this.get_history_values(ident, 'cash', ['price', 'date'], this.trade_date).then((response) => {
                this.$set(this.formdata, 'st_cur_rate', response.price)
            })
        },

        set_isin_data(data) {
            let fields = []
            if (this.asset === 'cash')
                fields = ['name'];
            else if (this.asset === 'equity')
                fields = ['name','cur','ric'];
            else if (this.asset === 'bond')
                fields = ['name','cur','company_name','sinkfactor','maturity'];
            else if (this.asset === 'future')
                fields = ['name','basedate','cur','expdate','ticks','tickv','uname'];
            else if (this.asset === 'option')
                fields = ['basedate','cur','dscode','expdate','name','strike','size','type','uname'];


            fields.forEach(k => {
                this.$set(this.isin_data, k, data[k])
            })
        }
    },

    watch: {
        isin_data(val) {
            this.$emit('change-isin', this.isin_data)
        },
        portfolio_cur(val) {
            this.get_tcur_rates_data();
        },

        "formdata.isin": function(val, old_val) {
            this.$emit('field-change', { field: 'isin', value:this.formdata.isin })
        },

        "formdata.tcur": function(val, old_val) {
            this.get_tcur_rates_data();
            this.$emit('field-change', { field: 'tcur', value:this.formdata.tcur })
        },

        "formdata.tpr": function(val, old_val) {
            if (val && (!old_val || old_val !== val)) 
                this.$set(this.formdata, 'tpr', this.format_number_field('tpr'));

            this.$emit('field-change', { field: 'tpr', value: money_to_num(this.formdata.tpr) })
        },

        "formdata.q": function(val, old_val) {
            if (val && (!old_val || old_val !== val))
                this.$set(this.formdata, 'q', this.format_number_field('q'));

            this.$emit('field-change', { field: 'q', value: money_to_num(this.formdata.q) })
        },

        "formdata.gross_amount": function(val, old_val) {
            if (val && (!old_val || old_val !== val))
                this.$set(this.formdata, 'gross_amount', this.format_number_field('gross_amount'));
        },

        "formdata.tcur_prate": function(val, old_val) {
            if (val && (!old_val || old_val !== val))
                this.$set(this.formdata, 'tcur_prate', this.format_number_field('tcur_prate'));

            this.$emit('field-change', { field: 'tcur_prate', value: money_to_num(this.formdata.tcur_prate) })
        },

        "formdata.tcur_prate_amount": function(val, old_val) {
            if (val && (!old_val || old_val !== val))
                this.$set(this.formdata, 'tcur_prate_amount', this.format_number_field('tcur_prate_amount'));
        },

        "formdata.st_cur": function(val, old_val) {
            this.$emit('field-change', { field: 'st_cur', value:this.formdata.st_cur })
        },

        "formdata.st_cur_rate": function(val, old_val) {
            this.$emit('field-change', { field: 'st_cur_rate', value: money_to_num(this.formdata.st_cur_rate) })
        },

        "formdata.coupon": function(val, old_val) {
            if (val && (!old_val || old_val !== val))
                this.$set(this.formdata, 'coupon', this.format_number_field('coupon'));

            this.$emit('field-change', { field: 'coupon', value:this.formdata.coupon })
        },
    }
}
